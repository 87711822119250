* {
    background:none !important;
    text-align:left !important;
    color:#000 !important; 
}
html { 
    margin:0 !important;
    padding:0 !important;
}
body {
    background:#fff !important;
    font-size:14pt !important;
    padding:0 !important;
    margin:10px !important;
}
a { color:#2976c9 !important; }
img{
    max-width: 100%;
    height: auto;
}
h1, h2, h3, h4, h5, h6, b, strong, th{font-weight:bold;}
h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote {margin-bottom: 1em !important; margin-top: 1em !important; }
h1{font-size:200%;}
h2{font-size:150%;}
h3{font-size:120%;}
h4{font-size:110%;}
h5{font-size:100%;}
h6{font-size:90%;}
i, cite, em, var, dfn, address{font-style:italic;}
u, ins {text-decoration: underline;}
s, strike, del {text-decoration: line-through;}
blockquote, blockquote:before, blockquote:after, q, q:before, q:after {quotes: none;}
blockquote:before, blockquote:after, q:before, q:after {content: '';}
blockquote{
  border-left:4px solid #ccc;
  padding-left:10px;
  margin-left:14px; 
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {top: -0.5em;}
sub {bottom: -0.25em;}
table, caption, tbody, tfoot, thead, tr, th, td{
  border-collapse: collapse;
  border-spacing: 0;
}
th, td{
  border:1px solid #ccc;
  padding:3px 7px;
  text-align:left;
  vertical-align:top;
}
th{
  border-color:#999;
  text-align:center;
  vertical-align:middle;
}
ul{ margin-left:1px; list-style: none;}
ul, ol{ padding-left:0; }
ul ul,
ul ol,
ol ul, 
ol ol{
  padding-left:1em;
  margin:0;
}
ul>li{list-style:none;}
dl dt{
  float:left;
  clear:left;
  padding-right:4px;
  font-weight:bold;
}

.print-hide,
.btn,
.btn-white,
.logo-fixed,
.logo-mobile,
.social-list,
.top-nav,
.nav-holder,
.accessibility,
.banner-block .photo-wrap .dv,
.banner-block .banner-col:nth-child(2),
.categories-block,
.filters-block,
.footer-top,
.footer-middle,
.share-list,
.options-form .jcf-select,
.tour-booking-popup,
form { display:none !important; }

.product-cols, .carousel, #footer {
    page-break-inside: avoid;
}
.section{
    margin-bottom: 30px;
}
.bulleted-list,
.bulleted-list2{
    margin-left: 1em;
}
.bulleted-list li, .bulleted-list2 li{
    list-style-type: disc;
}


.header-holder, .slideshow .slideset, .blogs-list, .gallery-list{
    height: auto !important;
}
.slideshow .slide{
    display: none !important;
}
.slideshow .slide.active{
    display: block !important;
    position: static;
}
#header{
    opacity: 1 !important;
}
.logo{
    margin: 0 0 10px;
}
#header{
    margin: 0 0 20px;
}
.product-col, .blogs-list li, .gallery-list li{
    position: static !important;
    display: inline-block;
    vertical-align: top;
    width: 48%;
}
.gallery-list li{
    width: 30%;
    padding: 0 1.5% 1.5%;
    margin: 0 -4px;
}
.product-box{
    padding: 0 10px 15px;
}
.product-box .image img{
    width: 100%;
}
.product-box .image{
    padding: 0 0 20px;
}
.two-cols .column{
    min-height: 1px !important;
}
.contact-block .locations-list li{
    display: inline-block;
    vertical-align: top;
    width: 48%;
    padding: 0 1%;
    margin: 0 -4px 0 0;
}
.options-form{
    display: block !important;
}
.discovery-block .js-slide-hidden{
    display: block !important;
    position: static !important;
}
